import {useUserStore, } from '@/assets/js/src/modules/auth/user'
import {getActivePinia, storeToRefs, } from 'pinia'
import {getCurrentInstance, watch, } from 'vue'
import {type User, } from '@/assets/js/src/app.d'

export function useUserStateSync () {
    let userStore = useUserStore(getActivePinia())
    const {currentUser: user, } = storeToRefs(userStore)
    const {proxy: vm, } = getCurrentInstance()

    vm.$page && watch(() => vm.$page, (newPage) => {
        if (newPage.props.loggedIn) {
            user.value = newPage.props.user as User
            user.value.jwt = newPage.props.jwt as String
        } else {
            user.value = null
        }
    }, {immediate: true, })

    return {
        user,
    }
}
