const getViolations = function (response) {
    if (!response.violations) {
        throw new Error('422 Konnte nicht verarbeitet werden.')
    }

    let violations = {}
    response.violations.forEach((v) => {
        if (violations[v.propertyPath]) {
            violations[v.propertyPath].push(v.message)
        } else {
            violations[v.propertyPath] = [ v.message, ]
        }
    })

    return {'violations': violations, }
}

const _fetch = function (url, data = null, method = 'GET', catchErrors = true, jsonData = true) {
    let options = {
        method,
        headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-PureApi': 'true',
        },
        credentials: 'same-origin',
    }

    if(jsonData) {
        options.headers['Content-type'] = 'application/json'
    }
    if (data) {
        options['body'] = jsonData ? JSON.stringify(data) : data
    }
    if (method == 'PATCH') {
        options.headers['Content-type'] = 'application/merge-patch+json'
    }

    return fetch(url, options).then(async (response) => {
        if (response.status === 204) {
            return {}
        }

        let json = await response.json()
        if (typeof json === 'string') {
            json = JSON.parse(json)
        }

        if (catchErrors) {
            if ([ 401, 400, ].includes(response.status)) {
                throw new Error(json.error ?? 'Zugriff verweigert!')
            }
            if (response.status === 404) {
                throw new Error(json.error ?? 'Nicht gefunden!')
            }
            if (response.status === 422) {
                return getViolations(json)
            }
            if (response.status === 500) {
                throw new Error(json.error ?? 'Server Error!')
            }
        }
        return json
    })
}

const Api = {
    get: (url, catchErrors = true) => _fetch(url, null, 'GET', catchErrors),
    post: (url, data, catchErrors = true) => _fetch(url, data, 'POST', catchErrors),
    postFile: (url, data, catchErrors = true) => _fetch(url, data, 'POST', catchErrors, false),
    patch: (url, data, catchErrors = true) => _fetch(url, data, 'PATCH', catchErrors),
    put: (url, data, catchErrors = true) => _fetch(url, data, 'PUT', catchErrors),
    delete: (url, catchErrors = true) => _fetch(url, null, 'DELETE', catchErrors),
}

export {
    Api,
}
