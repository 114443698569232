import {defineStore, getActivePinia, } from 'pinia'
import {Api, } from '@/assets/js/src/shared/Api'
import {useAlertStore, } from '@/assets/js/src/shared/alert/alert'
import {router, } from '@inertiajs/vue3'
import {type User, } from '@/assets/js/src/app.d'

export const useUserStore = defineStore('user', {
    state () {
        return {
            currentUser: {} as User,
            editUser: {},
        }
    },
    getters: {
        /*preference: (state) => (key) => {
            if (!state.currentUser || !state.currentUser.preferences) {
                return null
            }

            if (key in state.currentUser.preferences) {
                return state.currentUser.preferences[key]
            }

            return null
        },
        preferenceE: (state) => (key) => {
            if (!state.editUser || !state.editUser.preferences) {
                return null
            }

            if (key in state.editUser.preferences) {
                return state.editUser.preferences[key]
            }

            return null
        },*/
    },
    actions: {
        async saveUser ({user = null, }) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Die Nutzerdaten wurden gespeichert.'
            let response = await Api.patch(`/user/${user.id}`, user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload()
            return null
        },
        async createUser ({user = null, }) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Der Nutzer wurde erfolgreich erstellt.'
            let response = await Api.post('/user', user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload({only: [ 'users', ], })

            return null
        },
    },
})
