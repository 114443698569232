import {createInertiaApp, } from '@inertiajs/vue3'
import {createPinia, } from 'pinia'
import {createApp, h, } from 'vue'
import Layout from '@/assets/js/src/shared/layout/Layout.vue'
import 'bootstrap'
import {errorHandling, } from '@/assets/js/src/shared/alert/alert'

async function resolvePageComponent (name, pages) {
    for (const path in pages) {
        if (name.split('/').every((namePart) => path.includes(namePart))) {
            let {default: page, } = await pages[path]()
            page.layout = page.layout || Layout
            return typeof pages[path] === 'function' ? page : pages[path]
        }
    }

    throw new Error(`Page not found: ${name}`)
}

createInertiaApp({
    resolve: (name) => resolvePageComponent(name, import.meta.glob([ './modules/**/*Page.vue', ])),
    setup ({el, App, props, plugin, }) {
        const pinia = createPinia()
        let app = createApp({render: () => h(App, props), })
            .use(plugin)
            .use(pinia)
            .use(errorHandling)

        app.mount(el)
    },
})
