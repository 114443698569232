import {defineStore, getActivePinia, } from 'pinia'

export interface Alert {
    id: number
    type: string
    msg: string
    timeoutId: number
}

const MAX_ALERTS = 10
let ALERT_ID = 0

export const useAlertStore = defineStore('alert', {
    state () {
        return {
            alerts: [],
        }
    },
    getters: {
        hasAlerts: (state) => state.alerts.length,
    },
    actions: {
        showAlert (msg, type) {
            while (this.alerts.length >= MAX_ALERTS) {
                this.alerts.shift()
            }
            this.alerts.push({
                id: ++ALERT_ID,
                type: type,
                msg: msg,
            })
        },
        showError (msg) {
            this.showAlert(msg, 'danger')
        },
        showWarning (msg) {
            this.showAlert(msg, 'warning')
        },
        showSuccess (msg) {
            this.showAlert(msg, 'success')
        },
        showInfo (msg) {
            this.showAlert(msg, 'info')
        },
        dismissAlert (alertId) {
            this.alerts = this.alerts.filter((a) => a.id != alertId)
        },
        dismissAll () {
            this.alerts = []
        },
    },
})

export const errorHandling = {
    install (app) {
        app.config.errorHandler = function (err, vm, info) {
            console.error(err, vm, info)
            useAlertStore(getActivePinia()).showError(err.message)
        }
    },
}
