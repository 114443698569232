<template>
    <div
        id="snc-dialog"
        class="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-scrollable"
            :class="{'modal-lg':large}"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h1
                        id="staticBackdropLabel"
                        class="modal-title fs-5"
                    >
                        <slot name="title" />
                    </h1>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div class="modal-body">
                    <slot name="body">
                        Hallo
                    </slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Abbrechen
                        </button>
                        <button
                            v-if="confirmText"
                            type="button"
                            class="btn btn-primary"
                            @click="emit('confirm')"
                        >
                            {{ confirmText }}
                        </button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {onMounted, onBeforeUnmount,} from 'vue'
import {Modal,} from 'bootstrap/dist/js/bootstrap.esm.js'
import {useDialogStore, } from '@/assets/js/src/shared/dialog/dialog.js'
import {getActivePinia,} from 'pinia'

const emit = defineEmits([ 'confirm', ])

defineProps({
    confirmText: {
        type: String,
        default: 'OK',
    },
    large: {
        type: Boolean,
        default: false,
    },
})

let modal = null
let el = null

let hideDialog = () => {
    useDialogStore(getActivePinia()).$reset()
}

onMounted(()=>{
    el = document.getElementById('snc-dialog')
    modal = new Modal(el)
    el.addEventListener('hidden.bs.modal', hideDialog)
    modal.show()
    setTimeout(()=>{
        const input = document.querySelector('.modal-body input:first-of-type') as HTMLElement | null
        if (input != null) {
            input.focus()
        }
    },500)
})

onBeforeUnmount(()=>{
    modal.hide()
    setTimeout(()=>{
        modal.dispose()
    },500)
    el.removeEventListener('hidden.bs.modal', hideDialog)
})
</script>
